@import 'node_modules/@viking-eng/css/src/_variables.scss';

.error-content {
  padding: 40px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  @include media-breakpoint-down(md) {
    font-size: $medium-body-copy;
    line-height: 28px;
    padding: 40px 10px;
  }
}
